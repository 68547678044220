




























import {Vue, Component, Watch} from "vue-property-decorator";
import MyFriendsType from "./MyFriends";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import MessageNav from "@/components/MessageAndFriend/HeadNav/index.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import MyFriendsItem from "@/views/Friend/MyFriends/components/item/index.vue";
import { MyFriendsNavList } from './Model';
import {MessageStore} from "@/views/Message/Store";
import {getFriends} from "@/views/Friend/MyFriends/Server";
import { MyFriendsStore } from './Store';

@Component({name: "MyFriends",components:{ HeadTop,MessageNav,PullDownUpList,ListBottom,MyFriendsItem }})
export default class MyFriends extends ZoomPage implements MyFriendsType{
    addIcon = require("@/assets/icon/Basics/Add.png")
    navList = MyFriendsNavList
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let list = MyFriendsStore.getMyFriendsList;
        if (list.length) this.List = list;
        else this.handleUpData(true)
    }

    handlePullDown(){
        let upData = MyFriendsStore.getMyFriendsUpData;
        upData.pageNo = 1;
        MyFriendsStore.SetMyFriendsUpData(upData)
        this.PullDown = true
        this.StopUp = false
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return
        let upData = MyFriendsStore.getMyFriendsUpData;
        upData.pageNo += 1;
        MyFriendsStore.SetMyFriendsUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        getFriends().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < MyFriendsStore.getMyFriendsUpData.pageSize )this.StopUp = true;
            if ( bool )
                MyFriendsStore.SetMyFriendsList( res );
            else
                MyFriendsStore.SetMyFriendsList( MyFriendsStore.getMyFriendsList.concat(res) );
        })
    }

    get getMyFriendsList(){
        return MyFriendsStore.getMyFriendsList
    }
    @Watch("getMyFriendsList")
    changeMyFriendsList(newVal:any[]){
        this.List = newVal
    }

    get getHideNum(){
        return MessageStore.getNewestMessageFriendStatusNum
    }
}
