
















import {Vue, Component, Prop} from "vue-property-decorator";
import MyFriendsItemType from "./indexType";

@Component({name: "MyFriendsItem"})
export default class MyFriendsItem extends Vue implements MyFriendsItemType {

    get getPhoto() {
        return this.getData && this.getData.friendHeadImage || require("@/assets/icon/Basics/default.png")
    }

    get getToPath(){
        let { friendId = "",friendName = "",groupId = "",groupUrl = "",groupName = "" } = this.getData || {}
        if ( this.getData && friendId && friendName ){
            return `/chat?title=${friendName}&id=${friendId}&state=chat`
        }else if (groupId && groupName){
            return `/chat?title=${groupName}&id=${groupId}&state=groupChat`
        }else{
            return window.location.hash;
        }
    }

    @Prop(Object)
    data!: any

    get getData() {
        return this.data
    }

    @Prop(Number)
    index!: number

    get getIndex() {
        return this.index
    }
}
